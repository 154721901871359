.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f0f2f5, #ffffff);
}

.login-card {
  background: #ffffff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 380px;
  text-align: center;
}

.login-card h2 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
}

.login-card input {
  margin-bottom: 1rem;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.login-card input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.login-card button {
  padding: 0.8rem;
  border: none;
  border-radius: 6px;
  background: #007bff;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-card button:hover {
  background: #0056b3;
}

.google-signin {
  margin-top: 1rem;
  background-color: #db4437;
  color: #fff;
  font-size: 1rem;
  border-radius: 6px;
  padding: 0.8rem;
}

.google-signin:hover {
  background-color: #c23321;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}
