/* src/App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 20vh;
  overflow: visible;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* src/App.css or wherever your main content is styled */
.main-content {
  flex: 1 0 auto; /* Allow the main content to grow */
  overflow-y: auto; /* Make the main content scrollable */
  margin-top: 60px;
}
