/* Enhanced LandingPage.css for better UI/UX - Commercial Version after Login/Signup */

/* Animated gradient background */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #fff;
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.brand-name {
  font-size: 1.8rem;
  color: #007bff;
  font-weight: bold;
  margin: 0;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar a {
  text-decoration: none;
  color: #007bff;
  font-weight: 600;
  transition: color 0.3s;
}

.navbar a:hover {
  color: #0056b3;
}

.profile-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.profile-icon:hover {
  transform: scale(1.1);
}

.welcome-container {
  background-color: #fff;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  margin: 30px 0;
  max-width: 700px;
  width: 90%;
  animation: fadeIn 1.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.welcome-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.welcome-container p {
  font-size: 1.2rem;
  color: #555;
}

.actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1000px;
}

.action-card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
  text-align: left;
}

.action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.action-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #007bff;
}

.action-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.action-card p {
  font-size: 1rem;
  color: #666;
}
