

.groups-container {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  color: #333;
  display: flex;
  flex-direction: row;
}
 

.cards {
  margin-left: auto; 
  flex: 1; 
}

.filter-sort-container {
  position: fixed;
  left : 28%;
    top : 20%;
    width: 80%;
    margin: 20%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 12px;
}


.filter-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.filter label, .sort label {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.filter label input, .sort label input {
  margin-right: 10px;
}

.filter button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.filter button:hover {
  background-color: #004085;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 64, 133, 0.5);
}

.filter-icon {
  position: fixed;
  font-size: 2.5rem;
  top: 6%;
  left: 3%;
  z-index: 3;
  cursor: pointer;
  color: #007bff;
  transition: transform 0.3s;
}


.card-wrapper {
  display: flex;
  gap: 1.5rem;
  margin: 3rem;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  overflow: visible;
}

.card {
  width: 400px;
  min-height: 350px;
  overflow: hidden;
  overflow-x: hidden;
  perspective: 1000px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.card-front {
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.card-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

.card-body {
  padding: 1rem;
  color: #333;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5;
}

.card-footer {
  margin-top: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2em;
  color: #007bff;
}

.no-data {
  margin-top: 20px;
  color: #333;
  font-size: 1.2rem;
}

.no-data button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.no-data button:hover {
  background-color: #0056b3;
}

.filter-icon {
  position: absolute;
  top: 6%;
  left: 1.5%; 
  z-index: 3;
  cursor: pointer;
  color: #007bff;
  transition: transform 0.3s;
}

.filter-icon:hover {
  transform: scale(1.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1040;
}

.close-button {
  position: absolute;
  top: -20px;
  right: -15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}


@media (max-width: 1024px) {
  .card {
    width: calc(50% - 40px); 
  }

  .filter-sort-container {
    left : 28%;
    top : 30%;
    width: 80%;
    margin: 20%;
  }

  .overlay {
    display: block; /* Show overlay when filter is visible */
  }
}

@media (max-width: 768px) {
  .card {
    width: calc(100% - 40px); /* One card per row with margin */
  }

  .filter-sort-container {
    left : 28%;
    top :40%;
    width: 80%;
    margin: 20%;
  }

  .overlay {
    display: block; /* Show overlay when filter is visible */
  }
}

@media (max-width: 480px) {
  .filter-sort-container {
    left : 28%;
    top : 20%;
    width: 80%;
    margin: 20%;
  }

  .overlay {
    display: block; /* Show overlay when filter is visible */
  }
  
  .card {
    padding: 15px;
  }
}