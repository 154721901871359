.container {
  max-width: 800px;
  margin: auto;
  padding-top: 50px;
}

.create-group-form {
  background-color: #ffffff;
  width: 600px;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
  background-image: url('../assets/form-bg-pattern.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.create-group-form:hover {
  transform: scale(1.02);
}

.create-group-form h2 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
  font-weight: 700;
  text-align: center;
}

.create-group-form .form-group {
  margin-bottom: 25px;
}

.create-group-form .form-label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  color: #555;
}

.create-group-form .form-control {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.create-group-form .form-control:focus {
  border-color: #00bfa6;
  box-shadow: 0 0 10px rgba(0, 191, 166, 0.3);
  outline: none;
}

.create-group-form .btn-primary {
  background-image: linear-gradient(to right, #007bff, #00bfa6);
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease;
  font-weight: bold;
  color: #fff;
}

.create-group-form .btn-primary:hover {
  background-image: linear-gradient(to right, #0056b3, #008f80);
  transform: scale(1.05);
}

/* Additions for better visuals */
.create-group-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to right, #007bff, #00bfa6);
  border-radius: 15px 15px 0 0;
}

.background-container {
  background: linear-gradient(135deg, #f0f3fa, #e0e7ff);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .create-group-form {
    padding: 30px;
  }

  .create-group-form h2 {
    font-size: 24px;
  }

  .create-group-form .form-control {
    font-size: 14px;
  }

  .create-group-form .btn-primary {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .create-group-form {
    padding: 25px;
  }

  .create-group-form h2 {
    font-size: 22px;
  }

  .create-group-form .form-control {
    font-size: 12px;
  }

  .create-group-form .btn-primary {
    font-size: 14px;
  }
}